import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getGatsbyImageData } from 'gatsby-source-sanity';
import clientConfig from '../../client-config';
import { caption } from './layout/layout.module.css';
import { root, noMarginShowing } from './figure.module.css';
import { cn } from '../lib/helpers';
import { Link } from 'gatsby';

const Figure = ({ node, noMargin }) => {
  if (!node || !node.image) {
    return null;
  }
  const { link, route, size, align } = node;

  let width = null;
  let float = null;
  let marginTop = null;
  let textAlign = null;
  let paddingRight = null;
  let paddingLeft = null;
  if (size) {
    switch (size) {
      case 'small':
        width = '200';
        break;
      case 'medium':
        width = '340';
        break;
      case 'large':
        width = '640';
        break;
      case 'auto':
        break;
    }
  }
  if (align) {
    switch (align) {
      case 'left':
        float = 'left';
        paddingRight = '1em';
        marginTop = 0;
        break;
      case 'right':
        float = 'right';
        paddingLeft = '1em';
        marginTop = 0;
        break;
      case 'center':
        float = 'none';
        textAlign = 'center';
        break;
      case 'none':
        float = 'none';
        break;
    }
  }

  let content = () => {
    const gatsbyImageData = getGatsbyImageData(
      node.image,
      { layout: 'constrained', width: width ? width : 675 },
      clientConfig.sanity
    );
    return (
      <figure
        style={{ float, paddingRight, paddingLeft, textAlign, marginTop }}
        className={cn(root, noMargin && noMarginShowing)}
      >
        <GatsbyImage image={gatsbyImageData} alt={node.alt} />
        {node.caption && <figcaption className={caption} dangerouslySetInnerHTML={{ __html: node.caption }} />}
      </figure>
    );
  };

  if (link) {
    if (link.indexOf('http') === 0) {
      return (
        <a target="_blank" rel="noopener" href={link}>
          {content()}
        </a>
      );
    } else {
      return <Link to={link}>{content()}</Link>;
    }
  } else if (route) {
    return <Link to={`/${route.slug.current}`}>{content()}</Link>;
  }

  return content();
};

export default Figure;
