module.exports = {
  sanity: {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID || 'dp25jusq',
    dataset: process.env.GATSBY_SANITY_DATASET || 'production',
  },
  gatsby: {
    siteTitle: 'Rides N Motion',
    siteDescription: 'Rides N Motion',
    siteUrl: 'https://www.ridesnmotion.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
    userTwitter: '@phatrides', // Change for Twitter Cards
    author: 'Rides N Motion', // Author for RSS author segment and SEO schema
    authorUrl: 'https://www.ridesnmotion.com', // URL used for author and publisher schema, can be a social profile or other personal site
    shortTitle: 'Rides N Motion', // Used for App manifest e.g. Mobile Home Screen
  },
};
