import styled from 'styled-components';
import theme from '../../../../theme/cycle';

const BMBodyStyles = {
  bmBurgerButton: {
    position: 'relative',
    width: '36px',
    height: '30px',
    transition: 'all 0.3s ease-in',
  },
  bmBurgerBars: {
    background: `${theme.white}`,
    height: '5px',
    borderRadius: '3px',
  },
  bmBurgerBarsHover: {
    background: `${theme.white}`,
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: `${theme.white}`,
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
    background: `${theme.secondaryColor}`,
  },
  bmMenu: {
    background: `${theme.secondaryColor}`,
    margin: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#fff !important',
    padding: '0.8em',
  },
  bmItem: {
    display: 'block',
  },
  bmOverlay: {
    top: 0,
    background: 'rgba(0, 0, 0, 0.3)',
  },
};

const BMListStyle = styled.div`
  padding-bottom: 80px;
  .menu-item {
    display: block;
    margin: 10px 0;
    position: relative;
    color: ${(props) => props.theme.white};
    padding: 5px 0;
    &:before {
      content: '';
      height: 1px;
      width: 15px;
      background: ${(props) => props.theme.white};
      position: absolute;
      left: 0;
      bottom: 0;
      transition: all 0.3s ease-in;
    }
    &:hover {
      text-decoration: none;
      &:before {
        width: 90%;
      }
    }
  }
`;
const BMSocial = styled.div`
  position: absolute;
  bottom: 30px;
  p {
    font-size: 16px;
    color: ${(props) => props.theme.white};
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    li {
      padding: 0 8px;
      &:first-child {
        padding-left: 0;
      }
      a {
        color: ${(props) => props.theme.white};
      }
    }
  }
  @media only screen and (max-width: 912px) {
    position: relative;
    bottom: 40px;
  }
  @media only screen and (max-width: 480px) {
    position: absolute;
    bottom: 30px;
  }
`;
export { BMBodyStyles, BMListStyle, BMSocial };
