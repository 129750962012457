import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Menu from 'react-burger-menu/lib/menus/slide';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { BMBodyStyles, BMListStyle, BMSocial } from './burgerMenu.style';
import MenuLink from '../../../layout/menu-link';

const BurgerMenu = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
            socialMediaFacebookUrl
            socialMediaInstagramUrl
          }
          mainMenu: sanityMenu(slug: { current: { eq: "main-menu" } }) {
            ...Menu
          }
        }
      `}
      render={({ mainMenu, site }) => {
        const mainMenuLinks = mainMenu ? mainMenu.links : null;
        return (
          <Menu id="BurgerMenuWrap" right width={250} styles={BMBodyStyles}>
            <BMListStyle>
              {mainMenuLinks.map((link) => {
                return (
                  <div key={link._key}>
                    <MenuLink link={link} />
                    {link.subItems && link.subItems.length > 0 && (
                      <div className="subItemMenu">
                        {link.subItems.map((subLink) => {
                          return (
                            <div key={subLink._key}>
                              <MenuLink className="menu-item" link={subLink} />
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                );
              })}
            </BMListStyle>
            <BMSocial>
              <p>Follow us:</p>
              <ul>
                <li>
                  <a target="_blank" href={site.socialMediaFacebookUrl}>
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a target="_blank" href={site.socialMediaInstagramUrl}>
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </BMSocial>
          </Menu>
        );
      }}
    />
  );
};

export default BurgerMenu;
