import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, StaticQuery } from 'gatsby';

// favicons
import appleTouchIcon from '../../assets/images/favicons/apple-touch-icon.png';
import favIcon32x32 from '../../assets/images/favicons/favicon-32x32.png';
import favIcon16x16 from '../../assets/images/favicons/favicon-16x16.png';
import safariPinnedTab from '../../assets/images/favicons/safari-pinned-tab.svg';

function SEO({ description, lang, keywords, path = '/', title, seoImage, seoTitle, contentType = 'website' }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={({ gatsby: { config }, site, defaultShareImage }) => {
        const metaDescription = description || (site && site.description) || config.description || '';
        const siteTitle = (site && site.title) || config.title || '';
        // const twitterUsername = config.userTwitter;
        let url = config.siteUrl + path;
        // strip ending slash for canonical to match site preferences and sitemap
        url = url.endsWith('/') ? url.slice(0, -1) : url;
        const shareImage = seoImage || config.siteUrl + defaultShareImage.childImageSharp.gatsbyImageData.src;

        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={seoTitle || title}
            titleTemplate={seoTitle ? '%s' : `%s - ${siteTitle}`}
          >
            {url && <meta property="og:url" content={url} />}
            {url && <link rel="canonical" href={url} />}

            {shareImage && <meta name="image" content={shareImage} />}
            {shareImage && <meta property="og:image" content={shareImage} />}
            {shareImage && <meta name="twitter:image" content={shareImage} />}

            <meta name="description" content={metaDescription} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:title" content={seoTitle} />
            <meta property="og:type" content={contentType} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:title" content={seoTitle} />
            <meta name="twitter:card" content="summary_large_image" />
            {/*<meta name="twitter:creator" content={config.userTwitter} />*/}
            {/*{twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}*/}
            {keywords && keywords.length > 0 && <meta name="keywords" content={keywords.join(', ')} />}

            <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
            <link rel="icon" type="image/png" sizes="32x32" href={favIcon32x32} />
            <link rel="icon" type="image/png" sizes="16x16" href={favIcon16x16} />
            <link rel="mask-icon" href={safariPinnedTab} color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />

            <meta name="apple-mobile-web-app-title" content="Rides N Motion" />
            <meta name="application-name" content="Rides N Motion" />
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    gatsby: site {
      config: siteMetadata {
        siteUrl
        title
        description
        userTwitter
      }
    }

    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      title
      description
      keywords
    }

    defaultShareImage: file(relativePath: { eq: "share-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, layout: FIXED)
      }
    }
  }
`;
