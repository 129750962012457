import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';

import { Container } from '../../../reusecore/Layout';
import Box from '../../../reusecore/Box';
import SearchForm from '../../../search-form';
import NavigationWrap from './navigation.style';
import { StaticImage } from 'gatsby-plugin-image';
import { SiteContext } from '../../../cyclelayout';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import BurgerMenu from '../BurgerMenu';

const isBrowser = typeof window !== 'undefined';

const Navigation = () => {
  const [scroll, setScroll] = useState(false);
  const [isSearching, setSearching] = useState(false);
  useEffect(() => {
    if (isBrowser) {
      const onScroll = (e) => {
        window.pageYOffset > 80 ? setScroll(true) : setScroll(false);
      };
      window.addEventListener('scroll', onScroll);
      return () => window.removeEventListener('scroll', onScroll);
    }
  }, [scroll]);
  const { setIsRentalOpen } = useContext(SiteContext);
  const navigateToSearch = (val) => navigate(`/search_results?query=${val}`);
  const setIsSearching = (value) => setSearching(value);

  return (
    <NavigationWrap className={`nav-block ${scroll ? 'scrolled' : ''}`}>
      <Container>
        <Box className="navbar-wrap">
          <Link to="/" className="logo">
            <StaticImage
              loading="eager"
              placeholder="none"
              src="../../../../assets/images/RnMLogoBlue_white.png"
              alt="Rides N Motion logo"
            />
          </Link>
          <nav className="nav">
            <Link to="/shop" className="shop-btn">
              <span>Shop Store</span>
            </Link>
            <a
              href=""
              onClick={(e) => {
                setIsRentalOpen(true);
                e.preventDefault();
              }}
              className="buy-btn"
            >
              <span>Book Rental</span>
            </a>
            <a
              href=""
              onClick={(e) => {
                setSearching(!isSearching);
                e.preventDefault();
              }}
              className="search-btn"
            >
              <FontAwesomeIcon icon={faSearch} title="Search" />
            </a>
            <BurgerMenu />
          </nav>
        </Box>
        <div className={`top-search${isSearching ? ' show-search' : ''}`}>
          <SearchForm
            isHeader
            setIsSearching={setIsSearching}
            isSearching={isSearching}
            onSubmit={navigateToSearch}
            title="Search"
          />
        </div>
      </Container>
    </NavigationWrap>
  );
};

export default Navigation;
