import styled from 'styled-components';

const NavigationWrap = styled.header`
  background: #000000;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  padding: 10px 0;
  transition: all 0.3s ease 0s;

  .navbar-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo {
    display: block;
    transition: all 0.8s cubic-bezier(0.3, 0.8, 0.2, 1) 0s;
    width: 400px;
    //height: 78px;
  }
  .buy-btn,
  .shop-btn {
    margin-right: 10px;
    height: 40px;
    width: 140px;
    border-radius: 6px;
    display: block;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: ${(props) => props.theme.white};
    background: ${(props) => props.theme.secondaryColor};
    transition: all 0.3s ease-in;

    &:hover {
      text-decoration: none;
    }
  }
  .search-btn {
    margin-right: 20px;
    font-size: 24px;
    color: white;
  }
  &.scrolled {
    box-shadow: rgba(0, 0, 0, 0.05) 0 10px 30px;
    background: #000000;
    .buy-btn,
    .shop-btn {
      color: ${(props) => props.theme.white};
      background: ${(props) => props.theme.secondaryColor};
    }
  }

  .top-search {
    position: absolute;
    right: 50px;
    top: 80px;
    opacity: 0;
    z-index: 99;
    visibility: hidden;
    transition: all 0.3s ease 0s;
    .searchSubmit {
      color: #fff;
      margin-left: 0;
    }
    input::-webkit-input-placeholder {
      color: #000;
      -moz-opacity: 0.34;
      opacity: 0.34;
      -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=34);
      filter: alpha(opacity=34);
    }
  }
  .show-search {
    right: 15px;
    opacity: 1;
    z-index: 999;
    visibility: visible;
  }

  @media only screen and (max-width: 568px) {
    .show-search {
      right: 0;
    }
  }

  @media only screen and (max-width: 912px) {
    height: auto;
    min-height: 50px;

    .logo {
      width: 300px;
    }
  }

  .nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media only screen and (max-width: 568px) {
    .buy-btn {
      margin-right: 65px;
    }
    .logo {
      display: block;
      width: 260px;
    }
    .top-search {
      width: 100%;
    }
  }
  @media only screen and (max-width: 670px) {
    .buy-btn,
    .shop-btn {
      display: none;
    }
  }
`;

export default NavigationWrap;
